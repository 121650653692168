import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/articles-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Unshakeable: Your Guide to Financial Freedom`}</h1>
    <p>{`I have watched an old video from Gary Vee where he was talking with Tony Robbins and also promoting his new book Unshakeable. If I'm not mistaken the video was about 2/3 years old, but I liked the talk and decided to buy the book.`}</p>
    <p>{`These are my opinions about the book, notes and comments. If you have read the book, I would love to talk with you and hear your opinion about it.`}</p>
    <p><strong parentName="p">{`Takes from the book`}</strong></p>
    <ul>
      <li parentName="ul">{`How early you start investing counts`}</li>
      <li parentName="ul">{`The amount you start saving counts`}</li>
      <li parentName="ul">{`Being consistent counts`}</li>
      <li parentName="ul">{`Be aware of fees`}</li>
      <li parentName="ul">{`It's impossible to beat the target`}</li>
      <li parentName="ul">{`Invest in Index Funds`}</li>
      <li parentName="ul">{`Have a strategy`}</li>
      <li parentName="ul">{`Keep investing when the market is down`}</li>
      <li parentName="ul">{`Sell only when the market is high`}</li>
    </ul>
    <p><strong parentName="p">{`Questions`}</strong></p>
    <ul>
      <li parentName="ul">{`What is the right time to invest?`}
        <ul parentName="li">
          <li parentName="ul">{`Throughout the first 3 chapters, Tony Robbins mentions that timing is important, that if you invest at the right time, you will get the best returns.`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`Chapter 1`}</h2>
    <p className="subheader">Introduction and advices</p>
    <p>{`Tony Robbins spends a good amount of time mentioning his other books. I understand that this is a good sales place, but after a while, it gets old...`}</p>
    <p>{`This chapter is an introduction to the rest of the book, Tony Robbins uses known names in the financial world to expand on his advice.`}</p>
    <blockquote>
      <p parentName="blockquote">{`You don't have to predict the future to win (...) You have to focus on what you can control, not on what you can't`}</p>
    </blockquote>
    <p>{`This is a great piece of advice. At times we think that we need to be able to predict the market to be able to win, Tony Robbins says that to win you just need to focus on what you can't control.`}</p>
    <p>{`We are oftentimes paralysed by uncertainty and don't act when we should because we are afraid the bad times will come soon.`}</p>
    <p>{`There is also a good piece - be wary of actively managed mutual funds and all the fees hidden on them as that can eat a big chunk of your money.`}</p>
    <h2>{`Chapter 2`}</h2>
    <p className="subheader">Compouding, nobody can predict the future, long term pays off</p>
    <p>{`Compounding is one of the big secrets of the wealthy. Put aside a chunk of your money, let it brew for a long period of time and after many years that money will grow.`}</p>
    <p>{`How early you start will matter in the long term. So start early even if you don't put aside a huge chunk of money each month.`}</p>
    <blockquote>
      <p parentName="blockquote">{`You're never going to earn your way to financial freedom. The real route to riches is to set aside a portion of your money and invest it so that it compounds over many years. That's how you become wealthy while you sleep. That's how you make money your slave instead of being a slave to money. That's how you achieve true financial freedom.`}</p>
    </blockquote>
    <p>{`The long term always pays off, even if you have years where the market seems to be coming down, patience and resilience will be the key to success. Being consistent is extremely important, it's no use of waiting around hoping for better days and never act. `}</p>
    <blockquote>
      <p parentName="blockquote">{`The biggest danger isn't a correction or a bear market, it's being out of the market.`}</p>
    </blockquote>
    <p>{`It's worth to note that Tony Robbins is using the US market as an example and other markets might not do what he describes in his book. But he says that on average there has been a correction every year since 1900 and historically, the average correction lasted around 54 days.`}</p>
    <p>{`This made me realise that it's okay if the market moves down at times. When you play the long term game, these low times are good since you can invest more.`}</p>
    <h3>{`There isn't a secret sauce`}</h3>
    <p>{`We are always trying to find a secret. We are always seeking a crystal ball that could give us an insight into what will happen.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Freedom Fact 3: Nobody Can Predict Consistently Whether the Market Will Rise or Fall`}</p>
    </blockquote>
    <p>{`This was such a powerful thing to read. You can watch a lot of predictions and read a lot of things, but accurate predictions don't seem to happen all that often, in fact, Tony Robbins shows the movements of the market on page 33 with notes of all the headlines that predicted doom.`}</p>
    <p>{`The market actually moved higher. Even though some people might predict a downfall, they probably predicted wrong many other downfalls in the past.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Freedom Fact 4: The Stock Market Rises over Time Despite Many Short-Term Setbacks`}</p>
    </blockquote>
    <p>{`This is the positive look of Tony Robbins after looking at the market for a long time. Again he sells the idea that long term will always pay off.`}</p>
    <p>{`He also compares a period of 36 years from the Gulf wars, 9/11 and wars - 36 years and there had been only 9 bad years in the market.`}</p>
    <p>{`With this in mind: `}<span className="green">{`Short and medium-term doesn't look good. But the trajectory of the market in the long term is likely to be good.`}</span></p>
    <p>{`As an example, Tony Robbins shows how the market moved in all the bear markets. The example he gives is when the economy hit rock bottom in March 2009. On this date, the S&P 500 was down -57.6%, but then what happened next?`}</p>
    <blockquote>
      <p parentName="blockquote">{`The S&P 500 index surged by 69.5% over the next 12 months.`}</p>
    </blockquote>
    <p>{`When Tony Robbins wrote the book it was up 266%. So again, patience and having that long term vision will pay off!`}</p>
    <h2>{`Chapter 3`}</h2>
    <p className="subheader">Watch out for fees. Index funds!</p>
    <p>{`This chapter is all about the hidden fees that an actively managed fund has, some of them seem to be pretty random. `}</p>
    <p>{`This chapter can be summarised in a single point: `}<strong parentName="p">{`Invest in index funds`}</strong>{` since it's not an actively managed fund, you have lower fees.`}</p>
    <h2>{`Personal Take`}</h2>
    <p>{`I've struggled to finish off the book, not so much because the book wasn't pleasant to read, but because it set up a lot of expectations that weren't met as I kept on reading.`}</p>
    <p>{`It really felt that Tony Robbins was just trying to sell previous books or external services. The only real secret was - invest in index funds, diversify, start early, be aware of fees and become tax efficient.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      